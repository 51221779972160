<template>
  <loading :active="loading" :is-full-page="true"></loading>

  <consad-header></consad-header>

  <main>
    <carousel-cards></carousel-cards>
    <div class="description">
      <h2 class="text-uppercase">CONSAD</h2>
      <h3 v-show="showQuiz">
        O propósito principal dessa pesquisa é entender a sua percepção sobre o
        impacto da realização do Fórum CONSAD 2022 para o alcance dos ODS, com
        foco na transformação positiva e geração de valor para os diversos
        stakeholders, principalmente, para a sociedade. Contamos com a sua
        contribuição! Vamos começar?
      </h3>
      <h3 v-show="!showQuiz">Obrigado por responder nossa pesquisa!</h3>
    </div>

    <div class="main-content justify-content-between">
      <form @change="validateForm()" v-show="showQuiz && !loading">
        <div class="card mb-3" v-for="theme in themes" :key="theme">
          <div class="card-header">{{ theme }}</div>
          <div class="card-body">
            <div
              class="mb-3"
              v-for="question in quiz.questions[theme]"
              :key="question"
            >
              <h6 class="form-label required">
                {{ question.number }}) {{ question.name }}
              </h6>

              <div class="ms-3">
                <div
                  v-show="'note' in question && question.note != ''"
                  class="fst-italic text-secondary m-0 mb-2"
                  v-text="question.note"
                ></div>

                <template v-if="question.type == 'radio'">
                  <template v-if="'optionsDefaultSeall' in question">
                    <label
                      class="label-radio mb-1"
                      v-for="option in optionsDefaultSeall"
                      :key="option"
                      v-bind:class="{
                        'option-selected': question.answer === option,
                      }"
                    >
                      <input
                        type="radio"
                        v-model="question.answer"
                        :value="'' + option.nota"
                        :name="question.id"
                      />
                      <span>
                        <b>Nota {{ option.nota }}</b> - {{ option.description }}
                      </span>
                      <span class="checkmark"></span>
                    </label>
                  </template>
                  <template v-else>
                    <label
                      class="label-radio mb-1"
                      v-for="option in question.options"
                      :key="option"
                      v-bind:class="{
                        'option-selected': question.answer === option,
                      }"
                    >
                      <input
                        type="radio"
                        v-model="question.answer"
                        :value="option"
                        :name="question.id"
                      />
                      {{ option }}
                      <span class="checkmark"></span>
                    </label>

                    <template
                      v-if="
                        question.id == 8 && question.answer == 'Outro. Qual?'
                      "
                    >
                      <input
                        type="text"
                        v-model="question.answerOptional"
                        class="form-control"
                      />
                    </template>
                  </template>
                </template>

                <template v-if="question.type == 'radio-satisfacao'">
                  <div class="radio-satisfacao">
                    <template v-for="option in question.options" :key="option">
                      <input
                        :label="option"
                        type="radio"
                        v-model="question.answer"
                        :value="'' + option"
                        :name="question.id"
                      />
                    </template>
                  </div>
                </template>

                <template v-if="question.type == 'select-city-state'">
                  <div class="row">
                    <div class="col-xl-6 col-lg-12 mb-3">
                      <label class="form-label"> Estado: </label>
                      <Select2
                        v-model="stateSelected"
                        :settings="settingsSelect2"
                        :options="states"
                        @select="loadCities()"
                      >
                      </Select2>
                    </div>
                    <div class="col-xl-6 col-lg-12 mb-3">
                      <label class="form-label"> Cidade: </label>
                      <Select2
                        v-model="citySelected"
                        :settings="settingsSelect2"
                        :options="cities"
                        @select="fillStateCityQuestion(question)"
                      >
                      </Select2>
                    </div>
                  </div>
                </template>

                <template v-if="question.type == 'input'">
                  <input
                    type="text"
                    v-model="question.answer"
                    class="form-control"
                  />
                </template>

                <template v-if="question.type == 'checkbox'">
                  <div
                    class="px-2"
                    v-for="option in question.options"
                    :key="option"
                  >
                    <div class="fw-bold seall-active">
                      {{ option.place }}
                    </div>
                    <div
                      v-for="alternative in option.options"
                      :key="alternative"
                    >
                      <label
                        class="label-checkbox mb-1"
                        v-bind:class="{
                          'option-selected':
                            question.answer.includes(alternative),
                        }"
                      >
                        <input
                          type="checkbox"
                          :value="alternative"
                          :id="alternative"
                          v-model="question.answer"
                        />
                        <i class="fa fa-square-o unchecked"></i>
                        <i class="fa fa-check-square checked"></i>
                        {{ alternative }}
                      </label>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div v-show="downloading" id="certificado" class="certificado">
        <img src="../images/consad-certificado.png" />
        <div class="content">
          <h1 class="fw-bold cert-color">Certificado</h1>
          <h2 class="fw-normal cert-color">De Participação</h2>

          <h2 class="fw-bold cert-color">
            {{ quiz.questions["Identificação"][0].answer }}
          </h2>
        </div>
      </div>
    </div>
  </main>

  <footer>
    <img src="../images/barra_ods.svg" alt="Barra" class="w-100" />

    <div class="footer-items">
      <powered-by class="text-center"></powered-by>
      <button
        v-show="!showQuiz"
        @click="downloadCertificate()"
        type="button"
        class="btn-seall"
      >
        Baixar Certificado
      </button>
      <button
        v-show="!showQuiz"
        @click="goToOrganization()"
        type="button"
        class="btn-seall"
      >
        Fazer meu Mapa da Transformação
      </button>
      <button
        v-show="showQuiz"
        @click="save()"
        type="button"
        class="btn-seall"
        :disabled="!canSave || loading"
      >
        Salvar
      </button>
    </div>
  </footer>
</template>
<script>
import "vue-loading-overlay/dist/vue-loading.css";
import Loading from "vue-loading-overlay";
import PoweredBy from "./PoweredBy.vue";
import CarouselCards from "./CarouselCards.vue";
import ConsadHeader from "./ConsadHeader.vue";
import Select2 from "vue3-select2-component";
import UniversalCountryService from "../../services/universal-country/universal-country.service";
import OrganizationService from "../../services/organization/organization.service.js";
import AuthService from "../../services/auth/auth";
import domtoimage from "dom-to-image";
export default {
  name: "Consad",
  components: {
    Loading,
    PoweredBy,
    CarouselCards,
    ConsadHeader,
    Select2,
  },
  data() {
    return {
      quiz: {
        questions: {
          Identificação: [
            {
              id: 1,
              answer: "",
              name: "Você participou do Fórum CONSAD 2022 como:",
              number: 1,
              options: ["Painelista", "Participante", "Organização", "Outro"],
              type: "radio",
              theme: "Identificação",
            },
            {
              id: 2,
              answer: "",
              name: "Qual a sua raça/cor?",
              number: 2,
              options: [
                "Amarela",
                "Branca",
                "Negra: preta",
                "Negra: parda",
                "Indígena",
              ],
              type: "radio",
              theme: "Identificação",
            },
            {
              id: 3,
              answer: "",
              name: "Qual a sua faixa etária?",
              number: 3,
              options: [
                "Menos de 18 anos",
                "Entre 18 e 25 anos",
                "Entre 26 e 35 anos",
                "Entre 36 e 45 anos",
                "Entre 46 e 55 anos",
                "Entre 56 e 65 anos",
                "Mais de 65 anos",
              ],
              type: "radio",
              theme: "Identificação",
            },
            {
              id: 4,
              answer: "",
              name: "Com qual gênero você se identifica?",
              number: 4,
              options: [
                "Homem cisgênero - pessoa que foi designada como homem ao nascer e se identifica como homem",
                "Homem transgênero - pessoa que foi designada como mulher ao nascer e se identifica como homem",
                "Mulher cisgênero - pessoa que foi designada como mulher ao nascer e se identifica como mulher",
                "Mulher transgênero - pessoa que foi designada como homem ao nascer e se identifica como mulher",
                "Não binário ou gênero fluido - pessoa que não se identifica nem como homem nem como mulher",
              ],
              type: "radio",
              theme: "Identificação",
            },
            {
              id: 5,
              answer: "",
              name: "Você possui alguma deficiência?",
              number: 5,
              options: ["Sim", "Não", "Prefiro não dizer"],
              type: "radio",
              theme: "Identificação",
            },
          ],
          "Caracterização socioeconômica": [
            {
              id: 6,
              answer: "",
              name: "Qual é sua renda mensal familiar?",
              number: 6,
              options: [
                "Acima de 20 salários mínimos",
                "De 10 a 20 salários mínimos",
                "De 4 a 10 salários mínimos",
                "De 2 a 4 salários mínimos",
                "Até 2 salários mínimos",
                "Prefiro não dizer",
              ],
              type: "radio",
              note: "Essa informação é importante para entender sobre qual público geramos impacto. Pode confiar no nosso compliance, nosso objetivo é medir impacto com confiabilidade e sigilo às suas informações!",
              theme: "Caracterização socioeconômica",
            },
            {
              id: 7,
              answer: "",
              name: "De qual cidade você saiu para participar do Fórum CONSAD 2022?",
              number: 7,
              type: "select-city-state",
              theme: "Caracterização socioeconômica",
            },
            {
              id: 8,
              answer: "",
              answerOptional: "",
              name: "Como foi o seu deslocamento até Brasília-DF?",
              number: 8,
              options: ["Aéreo", "Terrestre", "Outro. Qual?"],
              type: "radio",
              suggest: true,
              theme: "Caracterização socioeconômica",
            },
            {
              id: 9,
              answer: "",
              name: "Em Brasília, onde você ficou hospedado?",
              number: 9,
              type: "input",
              note: "Favor registrar o endereço completo do local. Se você não ficou hospedado, pode responder em relação ao seu ponto de partida para o evento (Por exemplo, o aeroporto ou a rodoviária ou o endereço de residência).",
              theme: "Caracterização socioeconômica",
            },
            {
              id: 10,
              answer: "",
              name: "Você participou de quantos dias de evento?",
              number: 10,
              options: ["3 dias", "2 dias", "1 dia"],
              type: "radio",
              theme: "Caracterização socioeconômica",
            },
            {
              id: 11,
              answer: "",
              name: "Qual foi o principal meio usado para o seu deslocamento até o evento?",
              number: 11,
              options: [
                "Transporte coletivo",
                "Taxi/Aplicativo",
                "Moto",
                "Carona",
                "Patinete/Bicicleta",
                "A pé",
                "Outro",
              ],
              type: "radio",
              note: "Se você não ficou hospedado, pode responder em relação ao deslocamento aeroporto-evento ou rodoviária-evento.",
              theme: "Caracterização socioeconômica",
            },
            {
              id: 12,
              answer: "",
              name: "Você faz parte de alguma organização?",
              number: 12,
              options: [
                "Sim, faço parte do governo",
                "Sim, faço parte de uma empresa privada",
                "Sim, faço parte de uma OSC (Organização social)",
                "Sim, sou empreendedor",
                "Não, sou pessoa física",
              ],
              type: "radio",
              theme: "Caracterização socioeconômica",
            },
            {
              id: 13,
              answer: "",
              name: "Sua organização é:",
              number: 13,
              options: [
                "Governo",
                "Informal",
                "Startup",
                "Microempreendedor individual - MEI",
                "Micro, pequena ou média empresa - ME/PME",
                "Associação",
                "Fundação",
                "Cooperativa",
                "Grande organização",
              ],
              type: "radio",
              theme: "Caracterização socioeconômica",
            },
            {
              id: 14,
              answer: "",
              name: "Na sua organização, você está em qual nível de atividades?",
              number: 14,
              options: ["Estratégico", "Gestão", "Operacional"],
              type: "radio",
              theme: "Caracterização socioeconômica",
            },
          ],
          "Sobre a participação no CONSAD": [
            {
              id: 15,
              answer: [],
              name: "Você participou de quais painéis ou palestras do Fórum CONSAD 2022 no dia 22/março?",
              number: 15,
              options: [
                {
                  place: "AUDITÓRIO",
                  options: [
                    "(14h30) Solenidade de Abertura",
                    "(15h) Palestra Magna - Administração Pública na próxima década: “Como desenvolver uma agenda de engajamento para lidar com os seus desafios.”",
                    "(17h) Painel ENAP: “Gestão Pública Inovadora: Olhando para o futuro de Estados e Municípios”",
                    "(18h30) Keynote Speaker – Governo Digital",
                    "(19h15) Fala CFA: A governança nos municípios sob o enfoque do IGM-CFA",
                    "(10h30) Lançamento de Livros",
                  ],
                },
                {
                  place: "SALAS PLENÁRIAS",
                  options: [
                    "(16h30) Primeira “Sessão de Painéis”: Apresentação e Debates dos Artigos Selecionados.",
                  ],
                },
              ],
              optionsObject: true,
              type: "checkbox",
              theme: "Sobre a participação no CONSAD",
            },
            {
              id: 16,
              answer: [],
              name: "Você participou de quais painéis ou palestras do Fórum CONSAD 2022 no dia 23/março?",
              number: 16,
              options: [
                {
                  place: "AUDITÓRIO",
                  options: [
                    "(9h) Painel: SUSTENTABILIDADE “Financiabilidade Verde”",
                    "(10h) Painel: INFRA BRASIL “Plano Nacional de Logística (PNL) e seus impactos na infraestrutura de transportes.”",
                    "(11h30) Sessão Plenária: “Os Desafios de Empreender e Inovar no Setor Público.”",
                    "(14h30) Sessão Plenária: “O Servidor Público como Protagonista na Transformação Social”",
                    "(16h) Palestra BID: “Confiança: A chave para a coesão social e o crescimento na América Latina e Caribe”",
                    "(17h30) Sessão Plenária: “Reforma Administrativa”",
                  ],
                },
                {
                  place: "ARENA BID",
                  options: [
                    "(9h) Sessão Plenária Compras Públicas: “Gestão, transparência e desenvolvimento local”",
                    "(11h) Palestra: Diversidade",
                    "(16h) MESA ENAP: “Formação de Líderes no Setor Público: Boas práticas e lições aprendidas pela Enap.”",
                  ],
                },
                {
                  place: "SALAS PLENÁRIAS",
                  options: [
                    "(8h) Segunda “Sessão de Painéis”: Apresentação e Debates dos Artigos Selecionados.",
                    "(11h) Terceira “Sessão de Painéis”: Apresentação e Debates dos",
                    "(15h45) Quarta “Sessão de Painéis”: Apresentação e Debates dos Artigos Selecionados.",
                  ],
                },
              ],
              optionsObject: true,
              type: "checkbox",
              theme: "Sobre a participação no CONSAD",
            },
            {
              id: 17,
              answer: [],
              name: "Você participou de quais painéis ou palestras do Fórum CONSAD 2022 no dia 24/março?",
              number: 17,
              options: [
                {
                  place: "AUDITÓRIO",
                  options: [
                    "(8h30) Palestra FDC: “Integrando as perspectivas da organização e das pessoas no desenvolvimento de liderança”",
                    "(9h40) Painel CLP: Unidos Pelo Brasil",
                    "(10h40) Painel CLP: “Protagonismo Feminino no Setor Público”",
                    "(11h30) Talk Show: “Transição de Governo”",
                    "(12h30) Entrega do Prêmio Mérito CONSAD de Gestão Pública, premiação dos 05 melhores artigos e encerramento.",
                    "(13h30) Encerramento",
                  ],
                },
                {
                  place: "ARENA BID",
                  options: [
                    "(15h30) FÓRUM NACIONAL DOS SECRETÁRIOS DE ADMINISTRAÇÃO (Agenda interna)",
                  ],
                },
                {
                  place: "SALAS PLENÁRIAS",
                  options: [
                    "(8h) Quinta “Sessão de Painéis”: Apresentação e Debates dos Artigos Selecionados.",
                  ],
                },
              ],
              optionsObject: true,
              type: "checkbox",
              theme: "Sobre a participação no CONSAD",
            },
            {
              id: 18,
              answer: "",
              name: "Participar do CONSAD possibilitou a você novas conexões, negócios e/ou oportunidades?",
              number: 18,
              options: [
                "Não",
                "Sim, algumas conexões, mas pouco relevantes",
                "Sim, algumas conexões muito relevantes",
                "Sim, muitas conexões, mas pouco relevantes",
                "Sim, muitas conexões muito relevantes",
              ],
              type: "radio",
              theme: "Sobre a participação no CONSAD",
            },
            {
              id: 20,
              answer: "",
              name: "Em uma escala de 1 a 5, qual é a sua avaliação de satisfação geral com o evento?",
              number: 20,
              options: [1, 2, 3, 4, 5],
              note: "1 Muito insatisfeito(a) - 5 Muito satisfeito(a)",
              type: "radio-satisfacao",
              theme: "Sobre a participação no CONSAD",
            },
            {
              id: 21,
              answer: "",
              name: "Em uma escala de 1 a 5, qual é a sua avaliação sobre as palestras?",
              number: 21,
              options: [1, 2, 3, 4, 5],
              note: "1 Muito insatisfeito(a) - 5 Muito satisfeito(a)",
              type: "radio-satisfacao",
              theme: "Sobre a participação no CONSAD",
            },
            {
              id: 22,
              answer: "",
              name: "Em uma escala de 1 a 5, qual é a sua avaliação sobre os painéis?",
              number: 22,
              options: [1, 2, 3, 4, 5],
              note: "1 Muito insatisfeito(a) - 5 Muito satisfeito(a)",
              type: "radio-satisfacao",
              theme: "Sobre a participação no CONSAD",
            },
            {
              id: 23,
              answer: "",
              name: "Em uma escala de 1 a 5, qual é a sua avaliação sobre os artigos apresentados?",
              number: 23,
              options: [1, 2, 3, 4, 5],
              note: "1 Muito insatisfeito(a) - 5 Muito satisfeito(a)",
              type: "radio-satisfacao",
              theme: "Sobre a participação no CONSAD",
            },
            {
              id: 24,
              answer: "",
              name: "Em uma escala de 1 a 5, qual é a sua avaliação sobre o espaço físico?",
              number: 24,
              options: [1, 2, 3, 4, 5],
              note: "1 Muito insatisfeito(a) - 5 Muito satisfeito(a)",
              type: "radio-satisfacao",
              theme: "Sobre a participação no CONSAD",
            },
            {
              id: 25,
              answer: "",
              name: "Em uma escala de 1 a 5, qual é a sua avaliação sobre a organização?",
              number: 25,
              options: [1, 2, 3, 4, 5],
              note: "1 Muito insatisfeito(a) - 5 Muito satisfeito(a)",
              type: "radio-satisfacao",
              theme: "Sobre a participação no CONSAD",
            },
            {
              id: 26,
              answer: "",
              name: "Na sua percepção, qual foi a contribuição do Fórum CONSAD 2022 para o seu desenvolvimento profissional?",
              number: 26,
              optionsDefaultSeall: true,
              type: "radio",
              theme: "Sobre a participação no CONSAD",
            },
          ],
          "Percepção de contribuição em relação aos ODS": [
            {
              id: 27,
              answer: "",
              name: "Na sua percepção, qual foi a contribuição do Fórum CONSAD 2022 para:",
              number: 27,
              optionsDefaultSeall: true,
              note: "Acabar com a fome, gerar acesso a alimentos seguros e de qualidade, promover e apoiar nutrição adequada e reduzir as perdas e desperdícios de alimentos.",
              type: "radio",
              theme: "Percepção de contribuição em relação aos ODS",
            },
            {
              id: 28,
              answer: "",
              name: "Na sua percepção, qual  foi a contribuição do Fórum CONSAD 2022 para:",
              number: 28,
              optionsDefaultSeall: true,
              note: "Promover o bem estar e a saúde, gerar acesso a tratamentos e medicamentos, conscientizar e reduzir as incidências e fatalidades por doenças",
              type: "radio",
              theme: "Percepção de contribuição em relação aos ODS",
            },
            {
              id: 29,
              answer: "",
              name: "Na sua percepção, qual  foi a contribuição do Fórum CONSAD 2022 para:",
              number: 29,
              optionsDefaultSeall: true,
              note: "Promover e gerar acesso à serviços básicos e essenciais, como os de habitação, infraestrutura, acesso a energia, tecnologia ou serviços financeiros, entre outros.",
              type: "radio",
              theme: "Percepção de contribuição em relação aos ODS",
            },
            {
              id: 30,
              answer: "",
              name: "Na sua percepção, qual  foi a contribuição do Fórum CONSAD 2022 para:",
              number: 30,
              optionsDefaultSeall: true,
              note: "Possibilitar o acesso à educação e qualificação, formal ou não formal, em nível básico, técnico ou superior, de forma inclusiva e capaz de promover a aprendizagem e profissionalização, incluindo a educação para o desenvolvimento sustentável.",
              type: "radio",
              theme: "Percepção de contribuição em relação aos ODS",
            },
            {
              id: 31,
              answer: "",
              name: "Na sua percepção, qual  foi a contribuição do Fórum CONSAD 2022 para:",
              number: 31,
              optionsDefaultSeall: true,
              note: "Apoiar e desenvolver a agricultura, de forma sustentável, resiliente e inovadora, capaz de apoiar o desenvolvimento e promover a geração de emprego e renda.",
              type: "radio",
              theme: "Percepção de contribuição em relação aos ODS",
            },
            {
              id: 32,
              answer: "",
              name: "Na sua percepção, qual  foi a contribuição do Fórum CONSAD 2022 para:",
              number: 32,
              optionsDefaultSeall: true,
              note: "Fortalecer as tomadas de decisões responsivas, práticas de transparência e anticorrupção e gestão eficiente de organizações públicas e privadas.",
              type: "radio",
              theme: "Percepção de contribuição em relação aos ODS",
            },
            {
              id: 33,
              answer: "",
              name: "Na sua percepção, qual  foi a contribuição do Fórum CONSAD 2022 para:",
              number: 33,
              optionsDefaultSeall: true,
              note: "Promover e apoiar o desenvolvimento e geração de energias limpas, com fontes renováveis e seguras, capazes de contribuir com a melhoria da eficiência energética e mitigação das mudanças climáticas.",
              type: "radio",
              theme: "Percepção de contribuição em relação aos ODS",
            },
            {
              id: 34,
              answer: "",
              name: "Na sua percepção, qual  foi a contribuição do Fórum CONSAD 2022 para:",
              number: 34,
              optionsDefaultSeall: true,
              note: "Promover e incentivar o desenvolvimento econômico, a geração de emprego e renda, a diversificação e modernização das economias, além de valorizar a indústria, o empreendedorismo, os negócios locais e os micro e pequenos empresários e produtores.",
              type: "radio",
              theme: "Percepção de contribuição em relação aos ODS",
            },
            {
              id: 35,
              answer: "",
              name: "Na sua percepção, qual  foi a contribuição do Fórum CONSAD 2022 para:",
              number: 35,
              optionsDefaultSeall: true,
              note: "Garantir e promover os direitos fundamentais, em respeito e concordância à legislação e ao desenvolvimento sustentável.",
              type: "radio",
              theme: "Percepção de contribuição em relação aos ODS",
            },
            {
              id: 36,
              answer: "",
              name: "Na sua percepção, qual  foi a contribuição do Fórum CONSAD 2022 para:",
              number: 36,
              optionsDefaultSeall: true,
              note: "Garantir e promover o acesso ao saneamento seguro e de qualidade e a gestão responsável da água e esgoto, contribuindo para universalização do acesso à água potável em locais em situações de vulnerabilidade e condições climáticas desérticas e redução dos incidentes e doenças por falta de saneamento.",
              type: "radio",
              theme: "Percepção de contribuição em relação aos ODS",
            },
            {
              id: 37,
              answer: "",
              name: "Na sua percepção, qual  foi a contribuição do Fórum CONSAD 2022 para:",
              number: 37,
              optionsDefaultSeall: true,
              note: "Garantir e promover a igualdade de oportunidades para todas as pessoas, a igualdade de gênero, o respeito às pessoas com deficiência, o respeito a todas as diferenças, a não discriminação e a participação coletiva.",
              type: "radio",
              theme: "Percepção de contribuição em relação aos ODS",
            },
            {
              id: 38,
              answer: "",
              name: "Na sua percepção, qual  foi a contribuição do Fórum CONSAD 2022 para:",
              number: 38,
              optionsDefaultSeall: true,
              note: "Promover ou incentivar investimentos financeiros para o desenvolvimento da infraestrutura, da ciência e tecnologia, da medicina e dos mercados em prol do desenvolvimento sustentável da sociedade e das instituições.",
              type: "radio",
              theme: "Percepção de contribuição em relação aos ODS",
            },
            {
              id: 39,
              answer: "",
              name: "Na sua percepção, qual  foi a contribuição do Fórum CONSAD 2022 para:",
              number: 39,
              optionsDefaultSeall: true,
              note: "Promover e garantir a segurança de todos e o acesso à justiça, combater todas as formas de violência, enfrentar a ocorrência de crimes e delitos em todos os níveis",
              type: "radio",
              theme: "Percepção de contribuição em relação aos ODS",
            },
            {
              id: 40,
              answer: "",
              name: "Na sua percepção, qual  foi a contribuição do Fórum CONSAD 2022 para:",
              number: 40,
              optionsDefaultSeall: true,
              note: "Desenvolver e apoiar ações em prol da qualidade ambiental, incentivando a redução e mitigação da poluição da água, ar e solo, bem como a preservação e manutenção da biodiversidade (fauna e flora)",
              type: "radio",
              theme: "Percepção de contribuição em relação aos ODS",
            },
            {
              id: 41,
              answer: "",
              name: "Na sua percepção, qual  foi a contribuição do Fórum CONSAD 2022 para:",
              number: 41,
              optionsDefaultSeall: true,
              note: "Erradicar a pobreza e a vulnerabilidade, em todas as suas dimensões, para todas as pessoas em todos os lugares.",
              type: "radio",
              theme: "Percepção de contribuição em relação aos ODS",
            },
            {
              id: 42,
              answer: "",
              name: "Na sua percepção, qual  foi a contribuição do Fórum CONSAD 2022 para:",
              number: 42,
              optionsDefaultSeall: true,
              note: "Promover o uso responsável e sustentável dos recursos naturais, incluindo a redução dos resíduos gerados por meio de práticas de reciclagem e reuso",
              type: "radio",
              theme: "Percepção de contribuição em relação aos ODS",
            },
            {
              id: 43,
              answer: "",
              name: "Na sua percepção, qual  foi a contribuição do Fórum CONSAD 2022 para:",
              number: 43,
              optionsDefaultSeall: true,
              note: "Reduzir e controlar riscos sociais e ambientais, tais como desastres, acidentes, epidemias, contaminações e outros.",
              type: "radio",
              theme: "Percepção de contribuição em relação aos ODS",
            },
          ],
        },
        anoPesquisa: 2022,
      },
      themes: [
        "Identificação",
        "Caracterização socioeconômica",
        "Sobre a participação no CONSAD",
        "Percepção de contribuição em relação aos ODS",
      ],
      settingsSelect2: {
        width: "resolve",
        language: "pt-BR",
      },
      countrySelected: "Brazil",
      stateSelected: "",
      citySelected: "",
      countries: [],
      states: [],
      cities: [],
      loading: false,
      optionsDefaultSeall: [
        { nota: 1, description: "Nenhuma contribuição" },
        { nota: 2, description: "Contribuição baixa" },
        { nota: 3, description: "Contribuição média" },
        { nota: 4, description: "Contribuição alta" },
        { nota: 5, description: "Contribuição muito alta" },
      ],
      canSave: false,
      name: "",
      showQuiz: true,
      downloading: false,
    };
  },
  watch: {
    quiz(quiz) {
      this.name = quiz.questions["Identificação"][0].answer;
    },
  },
  mounted() {
    this.$i18n.locale = "pt";
    this.quiz.questions["Identificação"].unshift({
      id: 0,
      answer: "",
      name: "Qual seu nome?",
      number: 0,
      type: "input",
      theme: "Identificação",
    });
    this.loadInfo();
  },
  methods: {
    loadInfo() {
      this.loadCountries().then(() => {
        this.loading = true;
        OrganizationService.getConsad(
          AuthService.getCogId(),
          this.quiz.anoPesquisa
        ).then((response) => {
          const questions = response.data.questions;
          this.showQuiz = questions.length === 0;
          questions.forEach((q) => {
            this.themes.forEach((theme) => {
              const index = this.quiz.questions[theme].findIndex(
                (qt) => qt.id == q.id
              );

              if (index >= 0) {
                const answer = q.answer.includes("|")
                  ? q.answer.split("|")
                  : q.answer;

                if (
                  !Array.isArray(answer) &&
                  this.quiz.questions[theme][index].type === "checkbox"
                ) {
                  this.quiz.questions[theme][index].answer = [answer];
                } else {
                  this.quiz.questions[theme][index].answer = answer;
                }

                if (
                  this.quiz.questions[theme][index].type == "select-city-state"
                ) {
                  this.stateSelected = answer[0];
                  this.loadCities();
                  this.citySelected = answer[1];
                }
              }
            });
          });
          this.loading = false;
        });
      });
    },
    async loadCountries() {
      this.loading = true;
      await UniversalCountryService.getAll()
        .then((response) => {
          this.countries = response.data.body.filter(
            (c) => c.name == this.countrySelected
          );

          this.states = response.data.body
            .find((c) => c.name === this.countrySelected)
            .states.map((state) => state.name);

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    loadCities() {
      this.cities = this.countries
        .find((c) => c.name == this.countrySelected)
        .states.find((state) => state.name === this.stateSelected).cities;
    },
    fillStateCityQuestion(question) {
      question.answer = `${this.stateSelected}|${this.citySelected}`;
    },
    validateForm() {
      this.themes.forEach((theme) => {
        const nt = this.quiz.questions[theme].filter(
          (q) => q.answer.length == 0
        );
        this.canSave = nt.length === 0;
      });
    },
    save() {
      this.loading = true;
      const payload = {
        questions: [],
        info: {
          anoPesquisa: this.quiz.anoPesquisa,
        },
      };

      this.themes.forEach((theme) => {
        payload.questions.push(
          ...this.quiz.questions[theme].map((q) => {
            const property =
              "answerOptional" in q ? "answerOptional" : "answer";

            if (Array.isArray(q[property])) {
              q[property] = q[property].join("|");
            }

            return { id: q.id, answer: q[property] };
          })
        );
      });

      OrganizationService.saveConsad(AuthService.getCogId(), payload)
        .then(() => {
          this.loading = false;
          this.showQuiz = false;
          this.$toast.success(
            ` <h4><b>Questionário finalizado com sucesso!</b></h4>
              <p>Deseja iniciar o seu Mapa da Transformação?</p>
              <div class="d-flex justify-content-between">
                <a href="/map" class="btn btn-success">Quero</a>
                <a href="#" class="btn btn-secondary">Depois</a>
              </div>`,
            {
              type: "success",
              duration: false,
            }
          );
        })
        .catch(() => {
          this.loading = false;
          this.$notify({
            title: this.$t("common.error_http"),
            type: "error",
            duration: -100,
          });
        });
    },
    downloadCertificate() {
      const self = this;
      self.downloading = true;
      self.loading = true;
      domtoimage
        .toJpeg(document.getElementById("certificado"), { quality: 0.95 })
        .then(function (dataUrl) {
          self.downloading = false;
          self.loading = false;
          var link = document.createElement("a");
          link.download = "consad-2022-certificado.jpeg";
          link.href = dataUrl;
          link.click();
        });
    },
    goToOrganization() {
      window.location = "/organization";
    },
  },
};
</script>
<style lang="scss">
@import "../css/bootstrap/bootstrap.min.css";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;800&display=swap");
@font-face {
  font-family: Nunito-Regular;
  src: url(../fonts/Nunito/Nunito-Regular.ttf);
}
@font-face {
  font-family: NunitoSans-Regular;
  src: url(../fonts/Nunito_Sans/NunitoSans-Regular.ttf);
}
@font-face {
  font-family: Poppins-Regular;
  src: url(../fonts/Poppins/Poppins-Regular.ttf);
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px lightgray;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #492e85;
  border-radius: 5px;
}

html,
body {
  border: 0vh 0vw;
  height: 100%;
  margin: 0vh 0vw;
  padding: 0vh 0vw;
  width: 100%;
}

body {
  display: flex;
  flex-direction: column;
  padding: 0;
}

.cert-color {
  color: #2c68a4;
}

.certificado {
  position: relative;
  text-align: center;
  .content {
    h2 {
      font-family: "Montserrat", sans-serif;
    }
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

main {
  background-color: #e5e5e5;
  position: relative;

  .description {
    background: #ffffff;
    padding: 5vh 10vw;
    h2 {
      font-family: Poppins-Regular;
      font-style: normal;
      font-weight: bold;
      font-size: 32px;
      line-height: 133%;
      text-align: center;
      color: #533a8b;
      margin: 0vh 0vw;
      padding: 5vh 0vw 0vh 0vw;
    }

    h3 {
      color: #ffffff;
      font-family: NunitoSans-Regular;
      font-style: normal;
      font-weight: 300;
      font-size: 20px;
      line-height: 125%;
      text-align: center;
      color: #a3a3a3;
      text-align: center;
      margin: 0vh 0vw;
      padding: 2vh 0vw 0vh 0vw;
    }
  }

  .main-content {
    padding: 5vh 15vw;
    background: #ffffff;
    .card-header {
      font-family: Poppins-Regular;
      font-style: normal;
      font-weight: bold;
      color: #533a8b;
      background-color: #e5e5e5;
    }

    form {
      .label-radio,
      .label-checkbox {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        color: #a3a3a3;

        &:hover input ~ .checkmark {
          background-color: #ccc;
        }

        input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          &:checked ~ .checkmark {
            background-color: #533a8b;
          }
          &:checked ~ .checkmark:after {
            display: block;
          }
        }

        .checkmark {
          position: absolute;
          top: 0;
          left: 0;
          height: 20px;
          width: 20px;
          background-color: #eee;
          &:after {
            content: "";
            position: absolute;
            display: none;
          }
        }
      }

      .label-radio {
        .checkmark {
          border-radius: 50%;
          &:after {
            top: 7px;
            left: 7px;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background: white;
          }
        }
      }

      .label-checkbox {
        .checkmark {
          border-radius: 25%;
          &:after {
          }
        }

        i {
          font-size: 1.2rem !important;
        }

        input[type="checkbox"],
        .checked {
          display: none;
        }

        input[type="checkbox"]:checked ~ .checked {
          display: inline-block;
        }

        input[type="checkbox"]:checked ~ .unchecked {
          display: none;
        }
      }

      .option-selected {
        font-weight: 500;
        color: #533a8b;
      }

      .radio-satisfacao {
        display: flex;
        align-items: center;
        input {
          appearance: none;
          cursor: pointer;
          border: 1px solid #9d9d9c;
          margin: 0vh 0vw;
          padding: 1vh 2vh;
          background: #f5f5f5;
          color: #9d9d9c;
          font-family: Nunito-Regular;
          font-size: 2.5vh;
          font-family: NunitoSans-Regular;
          transition: all 200ms linear;
          &:checked {
            background: #492e85;
            color: #fff;
            border: 1px solid #533a8b;
            font-size: 2.5vh;
            padding: 1vh 2vh;
          }
          &:before {
            content: attr(label);
            display: inline-block;
            text-align: center;
            width: 100%;
          }
        }
      }
    }
  }
}

footer {
  height: 0vh;
  margin: 0vh 0vw;
  padding: 0vh 0vw;

  .footer-items {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0vh 20vw;
  }
  .btn-seall {
    color: #ffffff;
    background-color: #2f1764ff;
    border-color: #2f1764;
    border-radius: 5px;
    border-style: solid;
    border-width: thin;
    font-family: Nunito-Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    margin: 5vh 0vw;
    padding: 1vh 3vw;
  }
}

.v--default-css .c-toast--success {
  background-color: #492e85;
}
</style>